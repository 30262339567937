import { useState } from "react";
import "./Field.scss";

const Field = ({
    label,
    classNames,
    handleChange,
    value,
    name,
    id,
    type,
    required = false,
    errors, // use this to set the class name for errored input
    setErrors,
    placeholder,
    checked,
}) => {
    const [localError, setLocalError] = useState("");

    // setting local and global error for this field if required
    const handleBlur = () => {
        if (required && !value) {
            setLocalError(`${label} is required`);
            setErrors((prev) => {
                return { ...prev, [name]: "error" };
            });
            return;
        }
        setLocalError("");
        setErrors((prev) => {
            return { ...prev, [name]: "" };
        });
    };

    return type === "checkbox" ? (
        <div className="field">
            <label htmlFor={id} className="checkbox">
                <input
                    type="checkbox"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={name}
                    checked={checked}
                />
                {label}
            </label>
        </div>
    ) : type === "select" ? (
        <div className="select mb-4">
            <select name={name} id={id} onChange={handleChange} value={value}>
                <option value="1">London</option>
                <option value="2">Paris</option>
                <option value="3">Other</option>
            </select>
        </div>
    ) : (
        <div className="field">
            <label htmlFor={id} className="label">
                {label}:
            </label>
            <div className="control">
                {type === "textarea" ? (
                    <textarea
                        name={name}
                        id={id}
                        className="textarea"
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    ></textarea>
                ) : (
                    <input
                        type={type}
                        name={name}
                        id={id}
                        className={classNames}
                        value={value}
                        // prevent modal closing
                        // not quite sure why that was happening
                        // it wasn't triggering form submisson handler
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                            }
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={placeholder ? placeholder : ""}
                    />
                )}
            </div>
            {localError !== "" && (
                <p className="help is-danger">{localError}</p>
            )}
        </div>
    );
};

export default Field;
