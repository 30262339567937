import "./LoadingAndMessageModal.scss";

const LoadingAndMessageModal = ({
    displayModal = false,
    closeModal = () => {},
    displayMessage = false,
    renderMessage = () => {},
}) => {
    return (
        <div className={`modal${displayModal ? " is-active" : ""}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <div className="message-container">
                    {displayMessage ? (
                        <div>
                            {renderMessage()}
                            <button
                                onClick={closeModal}
                                className="button is-success is-centered-element mt-5"
                            >
                                Got it!
                            </button>
                        </div>
                    ) : (
                        <div className="loading-container">
                            <p className="is-size-4">
                                Please wait, making pledge...
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoadingAndMessageModal;
