export const API = {
    dev: false,
    prodUrl: "https://campbell-render-server.onrender.com/api/honeymoon",
    devUrl: "http://localhost:3001/api/honeymoon",
    getBaseUrl: function () {
        return this.dev ? this.devUrl : this.prodUrl;
    },

    getTest: function () {
        return fetch(this.getBaseUrl(), { method: "GET" });
    },

    getExperiences: function () {
        return fetch(`${this.getBaseUrl()}/experience`, { method: "GET" });
    },

    getPledges: function () {
        return fetch(`${this.getBaseUrl()}/pledge`, { method: "GET" });
    },

    createExperience: function (sendData) {
        return fetch(`${this.getBaseUrl()}/experience`, {
            method: "POST",
            body: JSON.stringify(sendData),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },

    updateExperience: function(id, sendData) {
        return fetch(`${this.getBaseUrl()}/experience/${id}`, {
            method: "PUT",
            body: JSON.stringify(sendData),
            headers: {
                "Content-Type": "application/json",                
            }
        })
    },

    deleteExperience: function(id) {
        return fetch(`${this.getBaseUrl()}/experience/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",                
            }
        })
    },

    createPledge: function (sendData) {
        return fetch(`${this.getBaseUrl()}/pledge`, {
            method: "POST",
            body: JSON.stringify(sendData),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },

    getPledgesByExperience: function (experienceId) {
        return fetch(`${this.getBaseUrl()}/pledgebyexperience/${experienceId}`, {
            method: "GET",
        });
    },

    updatePledge: function(pledgeId, sendData) {
        return fetch(`${this.getBaseUrl()}/pledge/${pledgeId}`, {
            method: "PUT",
            body: JSON.stringify(sendData),
            headers: {
                "Content-Type": "application/json",
            },
        })
    }
};
