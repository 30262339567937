import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import "./LandingPage.scss";
import MainHeading from "./MainHeading";
import SiteDescription from "./SiteDescription";
import ExperienceContainer from "./ExperienceContainer";
import ENUMS from "../../../constants";
import {
    selectScrollPosition,
    setWindowScrollPosition,
} from "../../../redux/slices/appSettings";

const LandingPage = ({ experiences }) => {
    const scrollPosition = useSelector(selectScrollPosition);
    const dispatch = useDispatch();

    // maintain scroll position between view experience page
    useEffect(() => {
        if (scrollPosition) {
            window.scroll(0, scrollPosition);
            dispatch(setWindowScrollPosition(0));
        }
        // eslint-disable-next-line
    }, []);

    const {
        Locations: { LONDON, PARIS },
    } = ENUMS;
    return (
        <div className="page-container">

            <MainHeading />

            <SiteDescription />

            <ExperienceContainer experiences={experiences} type={LONDON} />

            <section className="divider-main"></section>

            <ExperienceContainer experiences={experiences} type={PARIS} />           

            {/* <footer className="has-text-right">
                <a
                    href="https://www.flaticon.com/free-icons/bride"
                    title="bride icons"
                    target="_blank"
                    rel="noreferrer"
                >
                    Bride icons created by Freepik - Flaticon
                </a>
            </footer> */}
        </div>
    );
};

export default LandingPage;
