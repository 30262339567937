import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.scss";
import { API } from "./utils/API";
import {
    setExperiences,
    selectExperiences,
    setPledges,
} from "./redux/slices/appSettings";
import LandingPage from "./components/Pages/LandingPage";
import ExperiencePage from "./components/Pages/ExperiencePage";
import ManagementPage from "./components/Pages/ManagementPage";

function App() {
    const dispatch = useDispatch();
    const experiences = useSelector(selectExperiences);
    useEffect(() => {
        API.getExperiences()
            .then((response) => response.json())
            .then((data) => {
                const { experiences } = data;
                dispatch(setExperiences(experiences));
            })
            .catch((err) => {
                console.log({ err });
            });
    }, [dispatch]);

    useEffect(() => {
        API.getPledges()
            .then((response) => response.json())
            .then((data) => {
                const { pledges } = data;
                dispatch(setPledges(pledges));
            })
            .catch((err) => {
                console.log({ err });
            });
    }, [dispatch]);

    const landingPageProps = useMemo(() => {
        return {
            experiences,
        };
    }, [experiences]);

    const router = createBrowserRouter([
        {
            path: "/",
            element: <LandingPage {...landingPageProps} />,
        },
        {
            path: "experience/:experienceId",
            element: <ExperiencePage />,
        },
        {
            path: "michael/was/here",
            element: <ManagementPage />,
        },
    ]);

    return <RouterProvider router={router} />;
}

export default App;
