import { useMemo } from "react";
import ManagementJoinedExperience from "../../common/ManagementJoinedExperience/ManagementJoinedExperience";

const PledgesDisplay = ({ pledges, experiences }) => {
    const joinedExperiences = useMemo(() => {
        return experiences.map((experience) => {
            const expPledges = experience.pledges;
            const mappedPledges = expPledges.map((expPledge) => {
                return pledges.find((_pledge) => _pledge._id === expPledge);
            });
            return {
                ...experience,
                mappedPledges,
            };
        });
    }, [experiences, pledges]);

    return (
        <div className="pledges-list-container px-4">
            {joinedExperiences.map((joinedExp) => (
                <ManagementJoinedExperience
                    joinedExperience={joinedExp}
                    key={joinedExp._id}
                />
            ))}
        </div>
    );
};

export default PledgesDisplay;
