import { useState } from "react";
import { useDispatch } from "react-redux";
import {
    addExperience,
    removeExperience,
    updateExperiences,
} from "../../../redux/slices/appSettings";
import { API } from "../../../utils/API";
import Field from "../../common/Field/Field";
import "./ExperienceForm.scss";

const ExperienceForm = ({
    experience = {},
    setShowSettings = () => {},
    setDisplay = () => {},
}) => {
    const dispatch = useDispatch();
    const editingExperience = Object.keys(experience).length > 0;
    const [expTitle, setExpTitle] = useState(
        editingExperience ? experience.title : "",
    );
    const [expDesc, setExpDesc] = useState(
        editingExperience ? experience.description : "",
    );
    const [expImgUrl, setExpImgUrl] = useState(
        editingExperience ? experience.img_url : "",
    );
    const [expGoal, setExpGoal] = useState(
        editingExperience ? experience.goal_amount : "",
    );
    const [expDisplay, setExpDisplay] = useState(
        editingExperience ? experience.display : false,
    );
    const [expLocation, setExpLocation] = useState(
        editingExperience ? experience.location : 1,
    );
    const [expPermanent, setExpPermanent] = useState(
        editingExperience ? experience.permanent : false,
    );
    const [errors, setErrors] = useState({});

    const resetForm = () => {
        setExpTitle("");
        setExpDesc("");
        setExpImgUrl("");
        setExpGoal("");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let passValidation = true;
        setTimeout(() => {
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(errors)) {
                if (value === "error") {
                    passValidation = false;
                }
            }
            if (!passValidation) {
                return;
            }
            const submitData = {
                title: expTitle.trim(),
                description: expDesc.trim(),
                img_url: expImgUrl.trim(),
                goal_amount: `${expGoal}`.trim(),
                display: expDisplay,
                location: Number(expLocation),
                permanent: expPermanent,
            };
            if (editingExperience) {
                API.updateExperience(experience._id, submitData)
                    .then((response) => response.json())
                    .then((data) => {
                        console.log({ data });
                        const { updatedExperience } = data;
                        dispatch(updateExperiences(updatedExperience));
                        setShowSettings(false);
                    })
                    .catch((err) => console.log({ err }));
            } else {
                API.createExperience(submitData)
                    .then((response) => response.json())
                    .then((data) => {
                        const newExperience = data.experience;
                        dispatch(addExperience(newExperience));
                        resetForm();
                        setDisplay("experience-list");
                    })
                    .catch((err) => console.log({ err }));
            }
        }, 200);
    };

    const handleChange = (e) => {
        const val = e.target.value;
        switch (e.target.name) {
            case "title":
                setExpTitle(val);
                return;
            case "description":
                setExpDesc(val);
                return;
            case "img-url":
                setExpImgUrl(val);
                return;
            case "goal-amount":
                setExpGoal(val);
                return;
            case "display":
                const isCheckedDisplay = e.target.checked;
                setExpDisplay(isCheckedDisplay);
                return;
            case "location":
                setExpLocation(val);
                return;
            case "permanent":
                const isCheckedPermanent = e.target.checked;
                setExpPermanent(isCheckedPermanent);
                return;
            default:
                return;
        }
    };

    const handleDelete = (e, id, title) => {
        e.preventDefault();
        if (window.confirm(`Are you sure you want to delete ${title}?`)) {
            API.deleteExperience(id)
                .then((response) => response.json())
                .then((data) => {
                    const { id, success } = data;
                    if (success) {
                        dispatch(removeExperience(id));
                    }
                })
                .catch((err) => {
                    console.log({ err });
                });
        }
    };

    return (
        <div className="experience-form-container">
            <form className="form" onSubmit={handleSubmit}>
                <h2 className="is-size-3 has-text-centered">
                    {editingExperience
                        ? "Edit Experience"
                        : "Create an Experience"}
                </h2>
                <hr />
                <Field
                    label="Title"
                    handleChange={handleChange}
                    id="title"
                    name="title"
                    classNames="input"
                    type="text"
                    value={expTitle}
                    errors={errors}
                    setErrors={setErrors}
                    required
                />
                <Field
                    label="Description"
                    handleChange={handleChange}
                    id="description"
                    name="description"
                    classNames="input"
                    type="textarea"
                    value={expDesc}
                    errors={errors}
                    setErrors={setErrors}
                    required
                />
                <Field
                    label="Location"
                    type="select"
                    handleChange={handleChange}
                    id="location"
                    name="location"
                    classNames="input"
                    value={expLocation}
                    errors={errors}
                    setErrors={setErrors}
                    required
                />
                <Field
                    type="checkbox"
                    label=" Permanent "
                    handleChange={handleChange}
                    id="permanent"
                    name="permanent"
                    classNames=""
                    checked={expPermanent}
                    errors={errors}
                    setErrors={setErrors}
                />
                <Field
                    label="Image Url (Optional)"
                    type="text"
                    handleChange={handleChange}
                    id="img-url"
                    name="img-url"
                    errors={errors}
                    setErrors={setErrors}
                    classNames="input"
                    value={expImgUrl}
                />
                <Field
                    label="Goal Amount"
                    type="number"
                    handleChange={handleChange}
                    id="goal-amount"
                    name="goal-amount"
                    classNames="input"
                    value={expGoal}
                    errors={errors}
                    setErrors={setErrors}
                    required
                />
                {editingExperience && (
                    <>
                        <Field
                            type="checkbox"
                            label=" Display Experience "
                            handleChange={handleChange}
                            id="display"
                            name="display"
                            classNames=""
                            checked={expDisplay}
                            errors={errors}
                            setErrors={setErrors}
                        />
                    </>
                )}
                <div
                    className={editingExperience ? "field is-grouped" : "field"}
                >
                    <div className="control">
                        <button
                            className={`button is-info ${
                                editingExperience ? "is-small" : ""
                            }`}
                        >
                            {editingExperience
                                ? "Update Experience"
                                : "Create Experience"}
                        </button>
                    </div>
                    {editingExperience ? (
                        <div
                            className="control"
                            style={{ margin: "0 0 0 auto" }}
                        >
                            <button
                                className={`button is-danger ${
                                    editingExperience ? "is-small" : ""
                                }`}
                                onClick={(e) =>
                                    handleDelete(
                                        e,
                                        experience._id,
                                        experience.title,
                                    )
                                }
                            >
                                Delete
                            </button>
                        </div>
                    ) : null}
                </div>
            </form>
        </div>
    );
};

export default ExperienceForm;
