import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./ExperienceItem.scss";
import { setWindowScrollPosition } from "../../../redux/slices/appSettings";
import { useEffect } from "react";

const ExperienceItem = ({
    experience,
    pageView = false,
    handlePageClick = () => {},
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = (e) => {
        if (pageView) {
            handlePageClick();
        } else {
            dispatch(setWindowScrollPosition(window.scrollY));
            setTimeout(() => {
                navigate(`/experience/${experience._id}`);
            }, [100]);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div
            className={`experience-item${
                pageView ? " experience-item--page" : ""
            }`}
        >
            {pageView && (
                <Link
                    to="/"
                    className="button is-link is-inverted view-list-button"
                >
                    Back To List
                </Link>
            )}
            <div className={`banner${pageView ? " banner--page" : ""}`}>
                <h2 className="is-size-4">{experience.title}</h2>
                <p className="my-4">${experience.goal_amount}</p>
                <img
                    className={`experience-item__image${
                        pageView ? " experience-item__image--page" : ""
                    }`}
                    src={experience.img_url}
                    alt=""
                />
                {pageView && (
                    <p className="has-text-centered my-4">
                        {experience.description}
                    </p>
                )}
                <div
                    className={`action-continer${
                        pageView ? " action-container--page" : ""
                    }`}
                >
                    <button
                        className={`button${pageView ? " my-4" : ""}`}
                        onClick={handleClick}
                    >
                        {pageView ? "Make A Pledge" : "View"}
                    </button>
                </div>
            </div>
            <div className="border-corner top-left"></div>
            <div className="border-corner top-right"></div>
            <div className="border-corner bottom-left"></div>
            <div className="border-corner bottom-right"></div>
        </div>
    );
};

export default ExperienceItem;
