import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
    selectSpecificExperience,
    updateExperiences,
} from "../../../redux/slices/appSettings";
import { API } from "../../../utils/API";
import Field from "../../common/Field/Field";
import "./ExperiencePage.scss";
import ExperienceItem from "../../common/ExperienceItem";
import LoadingAndMessageModal from "../../common/LoadingAndMessageModal";

const ExpereiencePage = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const { experienceId } = useParams();
    const experience = useSelector((state) =>
        selectSpecificExperience(state, experienceId),
    );
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleCloseSuccess = () => {
        setTimeout(() => {
            setLoading(false);
            navigate("/");
        }, [100]);
    };

    useEffect(() => {
        let apiMounted = true;
        if (experienceId) {
            if (apiMounted) {
                // API.getPledgesByExperience(experienceId)
                //     .then((response) => response.json())
                //     .then((data) => {
                //         console.log({ data });
                //     })
                //     .catch((err) => {
                //         console.log({ err });
                //     });
            }
        }
        return () => {
            apiMounted = false;
        };
    }, [experienceId]);

    const clearForm = () => {
        setName("");
        setEmail("");
    };

    const handleCloseModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        clearForm();
        setModalOpen(false);
    };

    const handleChange = (e) => {
        const val = e.target.value;
        switch (e.target.name) {
            case "name":
                setName(val);
                return;
            case "email":
                setEmail(val);
                return;
            default:
                return;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let passValidation = true;
        setTimeout(() => {
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(errors)) {
                if (value === "error") {
                    passValidation = false;
                }
            }
            // add number validation for amount
            if (!passValidation) {
                return;
            }
            if (name === "" || email === "") {
                return;
            }

            // display loading state
            setModalOpen(false);
            setLoading(true);
            const submitData = {
                name: name.trim(),
                email: email.trim(),
                amount: Number(experience.goal_amount),
                experience: experience._id,
            };
            API.createPledge(submitData)
                .then((response) => response.json())
                .then((data) => {
                    const { updatedExperience } = data;
                    dispatch(updateExperiences(updatedExperience));
                    clearForm();
                    setDisplaySuccess(true);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }, 200);
    };

    const renderMessage = () => (
        <>
            <h3 className="is-size-4 has-text-centered">
                Thank you for your pledge!
            </h3>
            <p className="is-size-5 has-text-centered pt-4">
                Please send your gift to Steven and Randi through
            </p>
            <div className="tags-container pt-4">
                <div className="tags has-addons">
                    <span className="tag is-large is-info">Zelle</span>
                    <span className="tag is-large">407-873-6037</span>
                </div>
                <div className="tags has-addons">
                    <span className="tag is-large is-info">Venmo</span>
                    <span className="tag is-large">@Randi-Davis-17</span>
                </div>
            </div>
        </>
    );

    return (
        <div className="experience-page">
            <ExperienceItem
                experience={experience}
                pageView
                handlePageClick={() => setModalOpen(true)}
            />

            <LoadingAndMessageModal
                displayModal={loading}
                displayMessage={displaySuccess}
                renderMessage={renderMessage}
                closeModal={handleCloseSuccess}
            />

            {/* Gift Modal Form */}
            <div
                className={`modal${modalOpen && !loading ? " is-active" : ""}`}
            >
                <div
                    className="modal-background"
                    onClick={handleCloseModal}
                ></div>
                <div className="modal-card">
                    <form onSubmit={handleSubmit}>
                        <header className="modal-card-head">
                            <p className="modal-card-title">
                                {experience.title}
                            </p>
                            <button
                                className="delete"
                                aria-label="close"
                                onClick={handleCloseModal}
                            ></button>
                        </header>
                        <section className="modal-card-body">
                            <Field
                                label="Name"
                                classNames="input"
                                handleChange={handleChange}
                                value={name}
                                id="name"
                                name="name"
                                type="text"
                                required
                                errors={errors}
                                setErrors={setErrors}
                            />
                            <Field
                                label="Email"
                                classNames="input"
                                handleChange={handleChange}
                                value={email}
                                id="email"
                                name="email"
                                type="email"
                                required
                                errors={errors}
                                setErrors={setErrors}
                            />
                            <p className="is-size-4 has-text-centered">
                                Amount: ${experience.goal_amount}
                            </p>
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                className="card-footer-item button is-danger is-inverted"
                                onClick={handleCloseModal}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="card-footer-item button is-success"
                            >
                                Make Pledge!
                            </button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ExpereiencePage;
