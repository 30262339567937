import { useState, useRef } from "react";
import { API } from "../../../utils/API";
import { updatePledges } from "../../../redux/slices/appSettings";
import { useDispatch } from "react-redux";
import "./ManagementJoinedExperience.scss";

const PledgeDisplay = ({ pledge, experience }) => {
    const [displaySettings, setDisplaySettings] = useState(false);
    const { name, amount, fulfilled, email, _id } = pledge;

    const fulfillmentCheckboxRef = useRef();
    const dispatch = useDispatch();

    const handleSettingsClick = () => {
        setDisplaySettings((prev) => !prev);
    };

    const handleFulfillmentClick = () => {
        API.updatePledge(_id, {
            fulfilled: fulfillmentCheckboxRef.current.checked,
        })
            .then((response) => response.json())
            .then((data) => {
                const { updatedPledge } = data;
                dispatch(updatePledges(updatedPledge));
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    return (
        <div className="joined-experience">
            <div className="joined-experience__display">
                <p>
                    {displaySettings ? (
                        <button onClick={handleSettingsClick}>
                            <i className="fas fa-caret-up"></i>
                        </button>
                    ) : (
                        <button onClick={handleSettingsClick}>
                            <i className="fas fa-caret-down"></i>
                        </button>
                    )}
                </p>
                <p>{name}</p>
                <p className="has-text-centered">${amount.toFixed(2)}</p>
                <p className="has-text-right">
                    {fulfilled ? "paid" : "not paid"}
                </p>
            </div>
            {displaySettings && (
                <div className="joined-experience__settings">
                    <p className="setting">Email: {email}</p>
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name="fulfilled"
                            id="fulfilled"
                            defaultChecked={fulfilled}
                            ref={fulfillmentCheckboxRef}
                            onClick={handleFulfillmentClick}
                        />
                    </label>
                    {" Fulfilled "}
                </div>
            )}
        </div>
    );
};

const ManagementJoinedExperience = ({ joinedExperience }) => {
    const { mappedPledges } = joinedExperience;
    return (
        <div className="joined-experience-container">
            <h3 className="is-size-3 has-text-centered mb-4">{joinedExperience.title}</h3>
            {mappedPledges.map((pledge) => (
                <PledgeDisplay
                    pledge={pledge}
                    key={pledge._id}
                    experience={joinedExperience}
                />
            ))}
            {mappedPledges.length === 0 && (
                <p className="is-size-5 has-text-centered">
                    There are not any pledges for this experience
                </p>
            )}
        </div>
    );
};

export default ManagementJoinedExperience;
