import { useState } from "react";
import ExperienceForm from "../../Pages/ManagementPage/ExperienceForm";
import "./ManagementExperience.scss";

const ManagementExperience = ({ experience }) => {
    const { title } = experience;
    const [showSettings, setShowSettings] = useState(false);

    return (
        <div className="bg-management-experience">
            <div className="management-experience-container">
                <div className="display-container">
                    <h3 className="is-size-6">{title}</h3>
                    <button
                        onClick={() => {
                            setShowSettings((prev) => !prev);
                        }}
                        className="button is-info display-settings-button"
                    >
                        {showSettings ? "Hide Details" : "Show Details"}
                    </button>
                </div>
            </div>
            <div
                className={`settings-container${
                    showSettings ? " settings-container--active" : ""
                }`}
            >
                <ExperienceForm
                    experience={experience}
                    setShowSettings={setShowSettings}
                />
            </div>
        </div>
    );
};

export default ManagementExperience;
