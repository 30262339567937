import "./SiteDescription.scss";

const SiteDescription = () => (
    <section className="page-content">
        <div className="site-description-container px-5">
            <p className="content-text">
                Steve and Randi are getting married! They are going to be
                spending their honeymoon in Europe and would love for you to
                celebrate with them by gifting experiences.
            </p>
            <p className="content-text">
                Below is a list of places they will be going and items on their
                itenery. If you want to virtually toast them, select an
                experience and make a pledge to send them money through Venmo or
                Zelle.
            </p>
        </div>
    </section>
);

export default SiteDescription;
