import ENUMS from "../../../constants";
import ExperienceItem from "../../common/ExperienceItem";
import "./ExperienceContainer.scss";

const ExperienceContainer = ({ experiences, type }) => {
    const {
        Locations: { LONDON, PARIS },
    } = ENUMS;
    // we can make this a cool image or something
    // instead of just plain text
    const getHeadingText = () => {
        switch (type) {
            case LONDON:
                return "London";
            case PARIS:
                return "Paris";
            default:
                return "Other";
        }
    };

    return (
        <section className="experience-section">
            <h2 className="is-size-1 has-text-centered">{getHeadingText()}</h2>
            <div className="experience-item-container">
                {experiences
                    .filter(
                        (experience) =>
                            experience.display && experience.location === type,
                    )
                    .filter(
                        (experience) =>
                            experience.pledges.length === 0 ||
                            experience.permanent,
                    )
                    .map((experience, i) => (
                        <ExperienceItem experience={experience} key={i} />
                    ))}
            </div>
        </section>
    );
};

export default ExperienceContainer;
