import "./MainHeading.scss";

const MainHeading = () => (
    <section className="page-content">
        <header>
            <h1 className="brand-title real-fancy is-size-1 has-text-centered">
                <span className="name">Randi Davis</span>
                <span className="linebreak">and</span>
                <span className="name">Steve Freiberg</span>
            </h1>
            <div className="hero-content px-5">
                <div className="image-container">
                    <img
                        src="https://s3.amazonaws.com/uploads.hitchedup.com/0e244398-4af4-4a2c-b80f-99dcfec787fc.jpg"
                        alt=""
                    />
                </div>
            </div>
        </header>
    </section>
);

export default MainHeading;
