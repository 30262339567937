import { createSlice } from "@reduxjs/toolkit";

export const appSettingsSlice = createSlice({
    name: "appSettings",
    initialState: {
        experiences: [],
        pledges: [],
        scrollPosition: 0,
    },
    reducers: {
        setExperiences: (state, action) => {
            state.experiences = action.payload;
        },
        setPledges: (state, action) => {
            state.pledges = action.payload;
        },
        updateExperiences: (state, action) => {
            const currentExperiences = state.experiences;
            const updatedExperience = action.payload;
            const newExperiences = currentExperiences.map((experience) => {
                if (experience._id === updatedExperience._id) {
                    return updatedExperience;
                }
                return experience;
            });
            state.experiences = newExperiences;
        },
        removeExperience: (state, action) => {
            const id = action.payload;
            const currentExperiences = state.experiences;
            state.experiences = currentExperiences.filter(
                (exp) => exp._id !== id,
            );
        },
        updatePledges: (state, action) => {
            const updatedPledge = action.payload;
            const currentPledges = state.pledges;
            const newPledges = currentPledges.map((pledge) => {
                if (pledge._id === updatedPledge._id) {
                    return updatedPledge;
                }
                return pledge;
            });
            state.pledges = newPledges;
        },
        addExperience: (state, action) => {
            const currentExperiences = state.experiences;
            const newExperience = action.payload;
            state.experiences = [...currentExperiences, newExperience];
        },
        setWindowScrollPosition: (state, action) => {
            state.scrollPosition = action.payload;
        },
    },
});

export const {
    setExperiences,
    setPledges,
    updateExperiences,
    updatePledges,
    addExperience,
    removeExperience,
    setWindowScrollPosition,
} = appSettingsSlice.actions;

export const selectExperiences = (state) => state.appSettings.experiences;
export const selectPledges = (state) => state.appSettings.pledges;

export const selectSpecificExperience = (state, id) => {
    const { experiences } = state.appSettings;
    const renderExperience =
        experiences.find((experience) => experience._id === id) || {};
    return renderExperience;
};

export const selectPledgesByExperience = (state, id) => {
    const { pledges } = state.appSettings;
    const renderPledges = pledges.filter((pledge) => pledge.experience === id);
    return renderPledges;
};

export const selectScrollPosition = (state) => {
    return state.appSettings.scrollPosition;
};

export default appSettingsSlice.reducer;
