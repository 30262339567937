/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useState } from "react";
import { useSelector } from "react-redux";
import {
    selectExperiences,
    selectPledges,
} from "../../../redux/slices/appSettings";
import ExperienceForm from "./ExperienceForm";
import ManagementExperience from "../../common/ManagementExperience";
import "./ManagementPage.scss";
import PledgesDisplay from "./PledgesDisplay";

const ManagementPage = () => {
    const [display, setDisplay] = useState("experience-list");
    const [viewMobileMenu, setMobileMenu] = useState(false);
    const experiences = useSelector(selectExperiences);
    const pledges = useSelector(selectPledges);
    const handleDisplayClick = (e, displaySetting) => {
        e.preventDefault();
        setDisplay(displaySetting);
        setMobileMenu(false);
    };

    return (
        <div className="management-page-container">
            <nav
                className="navbar"
                role="navigation"
                aria-label="main navigation"
            >
                <div className="navbar-brand">
                    <a
                        role="button"
                        className="navbar-burger"
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarBasicExample"
                        onClick={() => setMobileMenu((prev) => !prev)}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div
                    id="navbarBasicExample"
                    className={`navbar-menu ${
                        viewMobileMenu ? " is-active" : ""
                    }`}
                >
                    <div className="navbar-start">
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a className="navbar-link">Experiences</a>

                            <div className="navbar-dropdown">
                                <a
                                    className="navbar-item"
                                    onClick={(e) =>
                                        handleDisplayClick(e, "experience-list")
                                    }
                                >
                                    View All
                                </a>
                                <a
                                    className="navbar-item"
                                    onClick={(e) =>
                                        handleDisplayClick(e, "create")
                                    }
                                >
                                    Create New
                                </a>
                            </div>
                        </div>
                        <div
                            className="navbar-item is-clickable"
                            onClick={(e) =>
                                handleDisplayClick(e, "pledges-list")
                            }
                        >
                            Pledges
                        </div>
                    </div>
                </div>
            </nav>
            <div className="management-content-container">
                {display === "create" && (
                    <div className="p-4">
                        <ExperienceForm setDisplay={setDisplay} />
                    </div>
                )}
                {display === "experience-list" && (
                    <div className="experience-list-container">
                        {experiences.map((experience) => (
                            <ManagementExperience
                                key={experience._id}
                                experience={experience}
                            />
                        ))}
                    </div>
                )}
                {display === "pledges-list" && (
                    <PledgesDisplay
                        pledges={pledges}
                        experiences={experiences}
                    />
                )}
            </div>
        </div>
    );
};

export default ManagementPage;
